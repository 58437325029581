<template>
    <section>
        <h1>Devolucion y glosas</h1>
    </section>
</template>
<script>
    export default {
        setup () {
            return {}
            }

        }
</script>
<style scoped>
</style>
